import { useContext, useCallback, useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cloneDeep from 'lodash/cloneDeep'
import { StaylistFetchContext } from 'components/common/staylistFetchContext'
import { findDataForCategoryId } from 'components/booking/context/bookingUtils'

const useStaylistAPIs = () => {
  const { allPrismicSiteDetail, allPrismicReservations, prismicRentals, prismicOfuro } = useStaticQuery(query)
  const { fetchStaylistEndpoint } = useContext(StaylistFetchContext)

  const prismicData = useMemo(() => allPrismicSiteDetail.edges
    .map(({ node }) => ({
      detail: node.data,
      type: 'site',
      categories: []
    }))
    .concat(prismicRentals.data.snow_peak_kit_items.map(item => ({
      detail: { ...item, modify_text: prismicRentals.data.modify_text },
      type: 'kit',
      categories: []
    })))
    .concat(prismicRentals.data.a_la_carte_items.map(item => ({
      detail: { ...item, modify_text: prismicRentals.data.modify_text },
      type: 'alaCarte',
      categories: []
    })))
    .concat(prismicRentals.data.snacks_items.map(item => ({
      detail: { ...item, modify_text: prismicRentals.data.modify_text },
      type: 'snack',
      categories: []
    }))), [allPrismicSiteDetail, prismicRentals])

  const checkAvailability = useCallback(async(bookingParams, folio) => {
    if (!bookingParams?.checkin || !bookingParams?.checkout) {
      return prismicData
    }

    const amenities = []
    if (bookingParams.adaAccessible) {
      amenities.push(155)
    }
    if (bookingParams.pets > 0) {
      amenities.push(114)
    }

    const params = {
      checkin: bookingParams.checkin,
      checkout: bookingParams.checkout,
      adults: bookingParams.adults,
      children: bookingParams.children,
      pets: bookingParams.pets,
      amenities: amenities
    }

    const categories = await fetchStaylistEndpoint({
      endpoint: 'availability',
      params: {
        ...params,
        grouping: 'categories'
      },
      errorTitle: 'Unable to Fetch Campsite Availability'
    })

    if (!categories) { return }

    const compositeCategories = categories
      .sort((a, b) => a.units[0]?.name.localeCompare(b.units[0]?.name))
      .map(category => ({
        ...category,
        units: category.units.sort((a, b) => a.name.localeCompare(b.name))
      }))

    if (folio) {
      const folioUnits = folio?.reservations?.map(reservation => ({ ...reservation.unit, inCart: true, reservation_uuid: reservation.uuid })) ?? []
      folioUnits.forEach(unit => {
        const curCategory = compositeCategories.find(category => category.id === unit.category.id)
        curCategory?.units.push(unit)
        curCategory?.units.sort((a, b) => a.name.localeCompare(b.name))
      })
    }

    return cloneDeep(prismicData).map(data => ({
      ...data,
      categories: compositeCategories.filter(category => {
        if (data.detail.staylist_id) {
          return data.detail.staylist_id === category.id
        }
        else if (data.detail.staylist_unit_category_ids) {
          return data.detail.staylist_unit_category_ids.map(catId => catId.id).includes(category.id)
        }
        return false
      })
    }))
  }, [fetchStaylistEndpoint, prismicData])

  const generateFolioDetails = useCallback(folioInfo => {
    if (!folioInfo) { return }
    folioInfo.reservations?.forEach(reservation => {
      const prismicInfo = findDataForCategoryId(prismicData, reservation.unit.category.id)
      if (prismicInfo) {
        reservation.detail = prismicInfo?.detail
        reservation.type = prismicInfo?.type
        if (reservation.type === 'site') {
          reservation.modify_text = allPrismicReservations.edges.find(({ node }) => node.uid === 'campsite')?.node.data.modify_text
        }
        else {
          reservation.modify_text = prismicRentals.data.modify_text
        }
      }
      else if (!!reservation.rates.find(rate => rate.type === 'timeslot')) {
        reservation.detail = { booking_image: prismicOfuro.data.booking_image }
        reservation.type = 'ofuro'
        reservation.modify_text = allPrismicReservations.edges.find(({ node }) => node.uid === 'ofuro')?.node.data.modify_text
      }
    })
    folioInfo.reservations?.sort((a, b) => {
      const dateCompare = a.checkin.localeCompare(b.checkin)
      if (dateCompare !== 0) {
        return dateCompare
      }
      if (a.rates?.length > 0 && b.rates?.length > 0 && a.rates[0].start_time && b.rates[0].start_time) {
        return a.rates[0].start_time.localeCompare(b.rates[0].start_time)
      }
      return 0
    })
  }, [prismicData, prismicOfuro, allPrismicReservations, prismicRentals])

  const getFolio = useCallback(async(folio_id, showError = true) => {
    if (process.env.LOG_ERRORS) {
      if (!folio_id) { console.error('Folio Details Endpoint: no folio id specified.') }
    }
    if (!folio_id) { return }

    const result = await fetchStaylistEndpoint({
      endpoint: `folio/${folio_id}`,
      errorTitle: 'Unable to retrieve folio',
      showError: showError
    })
    if (result) {
      result.folio_id = folio_id
      generateFolioDetails(result)
      return result
    }
  }, [fetchStaylistEndpoint, generateFolioDetails])

  const getContactFolios = useCallback(async(user_id) => {
    if (process.env.LOG_ERRORS) {
      if (!user_id) { console.error('Contact Folios Endpoint: no user id specified.') }
    }
    if (!user_id) { return }

    const result = await fetchStaylistEndpoint({
      endpoint: 'contact/folios',
      params: { id: user_id },
      errorTitle: 'Unable to Retrieve User Folio Info'
    })
    if (result) {
      result?.folios?.forEach(folio => {
        folio.folio_id = folio.id
        generateFolioDetails(folio)
      })
      return result?.folios
    }
  }, [fetchStaylistEndpoint, generateFolioDetails])

  const getRates = useCallback(async(rateData) => {
    if (!rateData.checkin || !rateData.checkout || !rateData.category_id) { return }
    const result = await fetchStaylistEndpoint({
      endpoint: 'quote/rates',
      params: rateData,
      errorTitle: 'Unable to Retrieve Daily Rates',
      showLoadingOverlay: false,
      showError: false
    })
    return result ?? []
  }, [fetchStaylistEndpoint])

  const getRatesList = useCallback(async(rateData) => {
    if (!rateData.start || !rateData.end || !rateData.category_id) { return }
    const result = await fetchStaylistEndpoint({
      endpoint: 'quote/ratesList',
      params: rateData,
      errorTitle: 'Unable to Retrieve Daily Rates',
      showLoadingOverlay: false,
      showError: false
    })
    return result ?? []
  }, [fetchStaylistEndpoint])

  const getUnits = useCallback(async() => {
    const result = await fetchStaylistEndpoint({
      endpoint: 'units',
      errorTitle: 'Unable to Retrieve Unit Info',
      showLoadingOverlay: false,
      showError: false
    })
    return result ?? []
  }, [fetchStaylistEndpoint])

  const deleteFolio = useCallback(async(folio_id) => {
    if (process.env.LOG_ERRORS) {
      if (!folio_id) { console.error('Delete Folio Endpoint: no folio id specified.') }
    }
    if (!folio_id) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: `folio/delete/${folio_id}`,
      post: true,
      errorTitle: 'Unable to Delete Folio'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const getReservationsList = useCallback(async(reservationParams) => {
    if (!reservationParams.start || !reservationParams.end || !reservationParams.unit_id) { return }
    const result = await fetchStaylistEndpoint({
      endpoint: 'availability/reservations',
      params: reservationParams,
      errorTitle: 'Unable to Retrieve Reservation Booking Data',
      showLoadingOverlay: false
    })
    return result ?? { status: 'error' }
  }, [fetchStaylistEndpoint])

  const createReservation = useCallback(async(reservationParams) => {
    if (process.env.LOG_ERRORS) {
      if (!reservationParams) { console.error('Create Reservation Endpoint: no reservation params.') }
      else {
        ['checkin', 'checkout', 'unit_id', 'adults'].forEach(field => {
          if (!reservationParams[field]) {
            console.error(`Create Reservation Endpoint: missing ${field} param.`)
          }
        })
      }
    }
    if (!reservationParams) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: 'reservation/create',
      params: reservationParams,
      post: true,
      errorTitle: 'Unable to Create Reservation'
    })
    if (result) {
      return { ...result, status: 'success' }
    }
  }, [fetchStaylistEndpoint])

  const deleteReservation = useCallback(async(reservation_uuid) => {
    if (process.env.LOG_ERRORS) {
      if (!reservation_uuid) { console.error('Delete Reservation Endpoint: no reservation uuid specified.') }
    }
    if (!reservation_uuid) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: `reservation/delete/${reservation_uuid}`,
      post: true,
      errorTitle: 'Unable to Delete Reservation'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const updateReservation = useCallback(async(reservation_uuid, params) => {
    if (process.env.LOG_ERRORS) {
      if (!reservation_uuid) { console.error('Update Reservation Endpoint: no reservation uuid specified.') }
      if (!params) { console.error('Update Reservation Endpoint: no params specified.') }
    }
    if (!reservation_uuid) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: `reservation/update/${reservation_uuid}`,
      params,
      post: true,
      errorTitle: 'Unable to Update Reservation'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const updateReservationTimeslot = useCallback(async(reservation_uuid, params) => {
    if (process.env.LOG_ERRORS) {
      if (!reservation_uuid) { console.error('Update Reservation Endpoint: no reservation uuid specified.') }
      if (!params) { console.error('Update Reservation Endpoint: no params specified.') }
    }
    if (!reservation_uuid) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: `reservation/updateTimeslot/${reservation_uuid}`,
      params,
      post: true,
      errorTitle: 'Unable to Update Reservation Timeslot'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const createReservationNote = useCallback(async(reservation_uuid, note) => {
    if (process.env.LOG_ERRORS) {
      if (!reservation_uuid) { console.error('Create Reservation Note Endpoint: no reservation uuid specified.') }
      if (!note) { console.error('Create Reservation Note Endpoint: no note specified.') }
    }
    if (!reservation_uuid || !note) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: `reservation/createNote/${reservation_uuid}`,
      params: { note: note },
      post: true,
      errorTitle: 'Unable to Create Reservation Note'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const createPaymentIntent = useCallback(async(paymentParams) => {
    if (!paymentParams) { return }
    return await fetchStaylistEndpoint({
      endpoint: 'transaction/createPaymentIntent',
      params: paymentParams,
      post: true,
      errorTitle: 'Unable to Create Payment Intent'
    })
  }, [fetchStaylistEndpoint])

  const createTransaction = useCallback(async(paymentIntent, folio_id, contact_id) => {
    if (!paymentIntent?.id || !paymentIntent.amount || !folio_id || !contact_id) { return { status: 'error' } }

    const result = await fetchStaylistEndpoint({
      endpoint: 'transaction/create',
      params: {
        context: 'folio',
        context_id: folio_id,
        token: paymentIntent.id,
        method: 'intent',
        amount: paymentIntent.amount / 100, // Stripe returns the amount in cents
        contact_id
      },
      post: true,
      errorTitle: 'Unable to Finalize Campsite Booking'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const createSavedTransaction = useCallback(async(token, amount, folio_id, contact_id) => {
    const result = await fetchStaylistEndpoint({
      endpoint: 'transaction/create',
      params: {
        context: 'folio',
        context_id: folio_id,
        existing_card: token.id,
        method: 'creditcard',
        amount: amount,
        contact_id
      },
      post: true,
      errorTitle: 'Unable to Finalize Campsite Booking'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const contactCreateToken = useCallback(async(token, contact_id) => {
    if (!token || !contact_id) { return { status: 'error' } }
    const result = await fetchStaylistEndpoint({
      endpoint: 'contact/createToken',
      params: {
        contact_id,
        token
      },
      post: true,
      errorTitle: 'Unable to Save Credit Card'
    })
    return { status: result ? 'success' : 'error' }
  }, [fetchStaylistEndpoint])

  const contactPaymentMethods = useCallback(async(id) => {
    const result = await fetchStaylistEndpoint({
      endpoint: 'contact/paymentMethods',
      params: { id },
      errorTitle: 'Unable to Retrieve Contact Payment Methods',
      showLoadingOverlay: false,
      showError: false
    })
    return result ?? { status: 'error' }
  }, [fetchStaylistEndpoint])

  return {
    checkAvailability,
    createReservation,
    updateReservation,
    updateReservationTimeslot,
    deleteReservation,
    createReservationNote,
    createPaymentIntent,
    createTransaction,
    createSavedTransaction,
    contactCreateToken,
    contactPaymentMethods,
    getFolio,
    deleteFolio,
    getRates,
    getRatesList,
    getUnits,
    getContactFolios,
    getReservationsList
  }
}

export default useStaylistAPIs

export const query = graphql`
  query {
    allPrismicSiteDetail(
      sort: {data: {order: ASC}}
      filter: {data: {active: {eq: true}}}
    ) {
      edges {
        node {
          data {
            staylist_unit_category_ids {
              id
            }
            info_title
            info_description {
              richText
            }
            info_short_description {
              richText
            }
            info_day_image {
              alt
              gatsbyImageData
            }
            info_ada_accessible
            info_dog_friendly
            info_private_bath
            info_guest_number
            info_guest_number_detail
            info_guest_number_mobile
            info_two_night_minimum
            info_amenities {
              amenity_title
              amenity_image {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allPrismicReservations {
      edges {
        node {
          data {
            modify_text {
              richText
            }
          }
          uid
        }
      }
    }
    prismicOfuro {
      data {
        booking_image {
          alt
          gatsbyImageData
        }
      }
    }
    prismicRentals {
      data {
        snow_peak_kit_title
        snow_peak_kit_description {
          richText
        }
        snow_peak_kit_items {
          title
          description {
            richText
          }
          listing {
            richText
          }
          main_image {
            alt
            gatsbyImageData
          }
          main_image_square {
            alt
            gatsbyImageData
          }
          detail_image {
            alt
            gatsbyImageData
          }
          staylist_id
          tent_site_only
          daily_price
        }
        a_la_carte_title
        a_la_carte_description {
          richText
        }
        a_la_carte_items {
          title
          description {
            richText
          }
          main_image {
            alt
            gatsbyImageData
          }
          staylist_id
          tent_site_only
          daily_price
        }
        snacks_title
        snacks_description {
          richText
        }
        snacks_items {
          title
          description {
            richText
          }
          main_image {
            alt
            gatsbyImageData
          }
          staylist_id
          price
        }
        staylist_rental_tent_id
        modify_text {
          richText
        }
      }
    }
  }
`
